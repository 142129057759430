import { IngredientDto } from "@api/ingredient/dto/ingredient.dto";
import { ProductDto } from "@api/product/dto/product.dto";
import { ProjectCreateDto } from "@api/project/dto/project-create.dto";
import { ProjectDictionariesDto } from "@api/project/dto/project-dictionaries.dto";
import { ProjectUpdateDto } from "@api/project/dto/project-update.dto";
import { ProjectDto } from "@api/project/dto/project.dto";

import { api as baseApi } from "store/api";
import {
    PROJECT_CACHE_TYPE,
    PROJECT_CACHE_LIST_ID,
    INGREDIENT_CACHE_TYPE,
    INGREDIENT_CACHE_LIST_ID,
    PRODUCT_CACHE_TYPE,
    PRODUCT_CACHE_LIST_ID,
} from "store/services/services.tags";
import { ApiRequestBody, ApiResponse } from "types/api.types";

const BASE_PATH = "projects";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [PROJECT_CACHE_TYPE, INGREDIENT_CACHE_TYPE, PRODUCT_CACHE_TYPE],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getProjects: builder.query<ApiResponse<ProjectDto>[], void>({
                query: () => BASE_PATH,
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: PROJECT_CACHE_TYPE, id } as const)),
                              { type: PROJECT_CACHE_TYPE, id: PROJECT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            getProject: builder.query<ApiResponse<ProjectDto>, number>({
                query: (id) => `${BASE_PATH}/${id}`,
                providesTags: (result) => (result ? [{ type: PROJECT_CACHE_TYPE, id: result.id }] : []),
            }),
            getProjectProducts: builder.query<ApiResponse<ProductDto>[], number>({
                query: (id) => `${BASE_PATH}/${id}/products`,
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: PRODUCT_CACHE_TYPE, id } as const)),
                              { type: PRODUCT_CACHE_TYPE, id: PRODUCT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            getProjectIngredients: builder.query<ApiResponse<IngredientDto>[], number>({
                query: (id) => `${BASE_PATH}/${id}/ingredients`,
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: INGREDIENT_CACHE_TYPE, id } as const)),
                              { type: INGREDIENT_CACHE_TYPE, id: INGREDIENT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            getProjectDictionaries: builder.query<ApiResponse<ProjectDictionariesDto>, void>({
                query: () => `${BASE_PATH}/dictionaries`,
            }),
            createProject: builder.mutation<ApiResponse<ProjectDto>, ApiRequestBody<ProjectCreateDto>>({
                query: (body) => ({
                    url: `${BASE_PATH}`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: (result) =>
                    result
                        ? [
                              { type: PROJECT_CACHE_TYPE, id: result.id },
                              { type: PROJECT_CACHE_TYPE, id: PROJECT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            updateProject: builder.mutation<
                ApiResponse<ProjectDto>,
                ApiRequestBody<{ id: number; body: ProjectUpdateDto }>
            >({
                query: (args) => ({
                    url: `${BASE_PATH}/${args.id}`,
                    method: "PUT",
                    body: args.body,
                }),
                invalidatesTags: (result) => (result ? [{ type: PROJECT_CACHE_TYPE, id: result.id }] : []),
            }),
        }),
    });

export const {
    useGetProjectsQuery,
    useGetProjectQuery,
    useCreateProjectMutation,
    useUpdateProjectMutation,
    useGetProjectIngredientsQuery,
    useGetProjectProductsQuery,
    useGetProjectDictionariesQuery,
} = api;
