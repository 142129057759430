import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
    accessToken?: string;
}

const authSlice = createSlice({
    name: "auth",
    initialState: {} as AuthState,
    reducers: {
        tokenReceived: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
    },
});

export const { tokenReceived } = authSlice.actions;
export const authReducer = authSlice.reducer;
