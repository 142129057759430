import { Configuration, LogLevel } from "@azure/msal-browser";

import { config } from "config";

export const authConfiguration: Configuration = {
    auth: {
        clientId: config.AD_AUTH_CLIENT_ID,
        authority: config.AD_AUTH_AUTHORITY,
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: true,
    },
    system: {
        loggerOptions: {
            piiLoggingEnabled: false,
            // eslint-disable-next-line no-undef
            logLevel: LogLevel.Error,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        // eslint-disable-next-line no-console
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        // eslint-disable-next-line no-console
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        // eslint-disable-next-line no-console
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        // eslint-disable-next-line no-console
                        console.warn(message);
                        return;
                }
            },
        },
    },
};
