import { useMsal } from "@azure/msal-react";
import styled from "styled-components";

import { useAuthUser } from "components/auth/auth.hooks";
import { LogoutIcon, UserIcon } from "components/common/Icons";
import { DialogConfirm } from "components/common/dialog/DialogConfirm";
import { SidebarLinkItem } from "components/layout/Sidebar/SidebarLinkItem";
import { sidebarItemCSS } from "components/layout/Sidebar/SidebarLinkItem/sidebar-link-item.styles";
import { SidebarSectionTitle } from "components/layout/Sidebar/SidebarSectionTitle";

const SidebarUserSectionStyled = styled.div`
    background-color: ${({ theme }) => theme.color.background.light};
    border-radius: ${({ theme }) => theme.borderRadius.md};
`;

const LogoutButton = styled.div`
    ${sidebarItemCSS};
`;

const SidebarUserSection = () => {
    const { instance } = useMsal();
    const user = useAuthUser();

    return (
        <div>
            <SidebarSectionTitle>Signed in</SidebarSectionTitle>
            <SidebarUserSectionStyled>
                <SidebarLinkItem to={"/user-profile"}>
                    <SidebarLinkItem.Icon>
                        <UserIcon size="lg" />
                    </SidebarLinkItem.Icon>
                    <SidebarLinkItem.Content>{user?.name}</SidebarLinkItem.Content>
                </SidebarLinkItem>
                <DialogConfirm
                    title="Logout from the application?"
                    content=""
                    confirmText="Yes, logout"
                    cancelText="No, stay signed in"
                    trigger={
                        <LogoutButton>
                            <SidebarLinkItem.Icon>
                                <LogoutIcon size="lg" />
                            </SidebarLinkItem.Icon>
                            <SidebarLinkItem.Content>Logout</SidebarLinkItem.Content>
                        </LogoutButton>
                    }
                    onConfirm={() => instance.logout()}
                />
            </SidebarUserSectionStyled>
        </div>
    );
};

export { SidebarUserSection };
