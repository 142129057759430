import { AccountInfo, InteractionRequiredAuthError, InteractionStatus, InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";
import { PropsWithChildren, useEffect } from "react";

import { useAuthUser, useUpsertAppUser } from "components/auth/auth.hooks";
import { PageLoader } from "components/common/ui/Loader/PageLoader";
import { config } from "config";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { tokenReceived } from "store/slices/auth.slice";

export const LoginProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const { instance, inProgress } = useMsal();
    const dispatch = useAppDispatch();
    const accessToken = useAppSelector((state) => state.auth.accessToken);

    const user = useAuthUser();
    try {
        useUpsertAppUser(instance);
    } catch (error) {
        console.error("Creating user failed", error);
    }

    useEffect(() => {
        if (!user || inProgress === InteractionStatus.None) {
            return;
        }
        if (config.MODE !== "production") {
            Sentry.setUser({ email: user.username });
            LogRocket.identify(user.username, {
                name: user.name ?? "missing-name",
            });
        }
        const acquireToken = async (user: AccountInfo) => {
            const request = { scopes: [config.AD_AUTH_SCOPES], account: instance.getActiveAccount() ?? user };
            try {
                const response = await instance.acquireTokenSilent(request);
                dispatch(tokenReceived(response.accessToken));
            } catch (err) {
                if (err instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(request); // Can be either redirect or popup https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#popup-apis
                }
            }
        };
        // noinspection JSIgnoredPromiseFromCall
        acquireToken(user);
    }, [user, instance, dispatch, inProgress]);

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {accessToken && children}
            {!accessToken && <PageLoader />}
        </MsalAuthenticationTemplate>
    );
};
