import { HTMLAttributes } from "react";
import styled from "styled-components";

type Props = Pick<HTMLAttributes<any>, "children">;

const DialogContentActionsStyled = styled.div`
    display: flex;
    gap: 1rem;

    padding: 2.5rem;
`;

const DialogContentActions = (props: Props) => {
    return <DialogContentActionsStyled {...props} />;
};

export { DialogContentActions };
export type { Props as DialogContentActionsProps };
