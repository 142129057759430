import { SidebarLinkItem as _SidebarLinkItem } from "components/layout/Sidebar/SidebarLinkItem/SidebarLinkItem";
import { SidebarLinkItemContent } from "components/layout/Sidebar/SidebarLinkItem/SidebarLinkItemContent";
import { SidebarLinkItemIcon } from "components/layout/Sidebar/SidebarLinkItem/SidebarLinkItemIcon";

const SidebarLinkItem = _SidebarLinkItem as typeof _SidebarLinkItem & {
    Content: typeof SidebarLinkItemContent;
    Icon: typeof SidebarLinkItemIcon;
};

SidebarLinkItem.Content = SidebarLinkItemContent;
SidebarLinkItem.Icon = SidebarLinkItemIcon;

export { SidebarLinkItem };
