import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";

const BreadcrumbsItemLink = styled(RouterLink)`
    padding: 0.5rem 0;

    color: unset;
    cursor: pointer;

    &:link {
        text-decoration: none;
    }

    &:hover {
        background-color: ${({ theme }) => theme.color.background.main};
        border-radius: ${({ theme }) => theme.borderRadius.md};

        color: ${({ theme }) => theme.color.text.dark};
    }
`;

export { BreadcrumbsItemLink };
