import { ReactNode } from "react";
import styled from "styled-components";

import { HomeIcon } from "components/common/Icons";
import { BreadcrumbsItem } from "components/common/ui/Breadcrumbs/BreadcrumbsItem";

interface Props {
    className?: string;
    children: ReactNode;
}

const BreadcrumbsStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: ${({ theme }) => theme.font.size.body.sm};
    line-height: 1;
`;

const Breadcrumbs = (props: Props) => {
    return (
        <BreadcrumbsStyled className={props.className}>
            <BreadcrumbsItem path="/">
                <HomeIcon />
            </BreadcrumbsItem>
            {props.children}
        </BreadcrumbsStyled>
    );
};

export { Breadcrumbs };
export type { Props as BreadcrumbsProps };
