import styled from "styled-components";

const SidebarSectionTitle = styled.div`
    padding: 2rem 1rem 0.5rem 1rem;
    text-transform: uppercase;

    font-size: 0.675rem;
    color: ${({ theme }) => theme.color.text.light};
    letter-spacing: 0.025rem;
    font-weight: 500;
`;

export { SidebarSectionTitle };
