import { Breadcrumbs } from "components/common/ui/Breadcrumbs";
import { PathBreadcrumbsItemBaseProps } from "components/layout/PathBreadcrumbs/path-breadcrumbs.types";
import { splitAndCapitalize } from "utils/string.utils";

type Props = Pick<PathBreadcrumbsItemBaseProps, "value" | "path">;

const PathBreadcrumbsTextItem = (props: Props) => {
    const title = splitAndCapitalize(props.value);
    return <Breadcrumbs.Item path={props.path}>{title}</Breadcrumbs.Item>;
};

export { PathBreadcrumbsTextItem };
