import { useEffect, useState } from "react";

export const useDebounce = (ms = 200) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, ms);

        return () => {
            clearTimeout(timeout);
        };
    }, [ms]);

    return show;
};
