import { FullTagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";

import { CacheTagType } from "store/services/services.tags";
import { notNil } from "utils/validation.utils";

export function tagsForOptionalIds<T extends CacheTagType>(tagType: T, ids: (number | string | null)[]) {
    const idSet = new Set(ids.filter((idItem) => notNil(idItem)));
    const tagArray = [...idSet].map((id) => ({ type: tagType, id } as const));

    return tagArray as FullTagDescription<T>[];
}
