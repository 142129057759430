/**
 * Split the string by capitals and merge using a space, capitalize first letter
 *
 * @param {string} value
 * @return {*}  {string}
 */
export const splitAndCapitalize = (value: string): string =>
    value.charAt(0).toUpperCase() + value.slice(1).replace(/([A-Z])/g, " $1");

export const normalize = (value: string): string =>
    value
        .toLowerCase()
        .replace(/\s/g, "")
        // Remove diacritics
        // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

export const isEmail = (value: string): boolean => {
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(value);
};
