import styled from "styled-components";

import { Typography } from "components/common/typography";
import { HeadlineProps } from "components/common/typography/Headline";

type Props = Pick<HeadlineProps, "children" | "Icon">;

const DialogContentTitleStyled = styled(Typography.Headline)`
    padding: 2.5rem 2.5rem 0 2.5rem;
`;

const DialogContentTitle = (props: Props) => {
    return <DialogContentTitleStyled {...props} />;
};

export { DialogContentTitle };
export type { Props as DialogContentTitleProps };
