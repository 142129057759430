import { up } from "styled-breakpoints";
import styled, { css, Breakpoints } from "styled-components";

import { Spacer } from "components/common/layout/Spacer";

type GridValues = {
    templateColumns?: string;
    templateRows?: string;
    templateAreas?: string;
    gap?: string;
    justifyItems?: "start" | "end" | "center" | "stretch";
    alignItems?: "start" | "end" | "center" | "stretch";
    justifyContent?: "start" | "end" | "center" | "space-between" | "space-evenly" | "space-around" | "stretch";
    alignContent?: "start" | "end" | "center" | "space-between" | "space-evenly" | "space-around" | "stretch";
    autoFlow?: "row" | "column" | "row dense" | "column dense";
    autoColumns?: string;
    autoRows?: string;
};

type GridProps = GridValues & Partial<Record<Breakpoints, GridValues>>;

const responsiveValues = (key: Breakpoints) => (props: GridProps) =>
    css`
        grid-template-columns: ${props[key]?.templateColumns ?? ""};
        grid-template-rows: ${props[key]?.templateRows ?? ""};
        grid-template-areas: ${props[key]?.templateAreas ?? ""};
        gap: ${props[key]?.gap ?? ""};
        justify-items: ${props[key]?.justifyItems ?? ""};
        align-items: ${props[key]?.alignItems ?? ""};
        justify-content: ${props[key]?.justifyContent ?? ""};
        align-content: ${props[key]?.alignContent ?? ""};
        grid-auto-flow: ${props[key]?.autoFlow ?? ""};
        grid-auto-columns: ${props[key]?.autoColumns ?? ""};
        grid-auto-rows: ${props[key]?.autoRows ?? ""};
    `;

export const Grid = styled((props) => <Spacer {...props} />)<GridProps>`
    display: grid;
    grid-template-columns: ${(props) => props.templateColumns ?? ""};
    grid-template-rows: ${(props) => props.templateRows ?? ""};
    grid-template-areas: ${(props) => props.templateAreas ?? ""};
    gap: ${(props) => props.gap ?? ""};
    justify-items: ${(props) => props.justifyItems ?? ""};
    align-items: ${(props) => props.alignItems ?? ""};
    justify-content: ${(props) => props.justifyContent ?? ""};
    align-content: ${(props) => props.alignContent ?? ""};
    grid-auto-flow: ${(props) => props.autoFlow ?? ""};
    grid-auto-columns: ${(props) => props.autoColumns ?? ""};
    grid-auto-rows: ${(props) => props.autoRows ?? ""};

    ${up("sm")} {
        ${responsiveValues("sm")}
    }

    ${up("md")} {
        ${responsiveValues("md")}
    }

    ${up("lg")} {
        ${responsiveValues("lg")}
    }

    ${up("xl")} {
        ${responsiveValues("xl")}
    }
    ${up("xxl")} {
        ${responsiveValues("xxl")}
    }
`;
