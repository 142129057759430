import z from "zod";

const stringSchema = z.string({ required_error: "Not defined" });

const configSchema = z.object({
    MODE: z.enum(["production", "acceptance", "test", "test-e2e", "development"]).optional(),
    API_URL: stringSchema.url(),
    AD_AUTH_CLIENT_ID: stringSchema.uuid(),
    AD_AUTH_AUTHORITY: stringSchema,
    AD_AUTH_SCOPES: stringSchema,
    AD_USER_GROUP: stringSchema.uuid(),
    SENTRY_DSN: stringSchema.optional(),
    LOGROCKET_TOKEN: stringSchema.optional(),
    DMA_URL: stringSchema.url().refine((val) => val.at(-1) !== "/", {
        message: "url should not end with the character '/'",
    }),
    DMA_FORMULATION_IMPORT_PATH: stringSchema.refine((val) => val.at(0) === "/", {
        message: "endpoint should start with the character '/'",
    }),
});

let config: z.infer<typeof configSchema>;
const result = configSchema.safeParse({
    MODE: import.meta.env.MODE,
    API_URL: import.meta.env.VITE_API_URL,
    AD_AUTH_CLIENT_ID: import.meta.env.VITE_AD_AUTH_CLIENT_ID,
    AD_AUTH_AUTHORITY: import.meta.env.VITE_AD_AUTH_AUTHORITY,
    AD_AUTH_SCOPES: import.meta.env.VITE_AD_AUTH_SCOPES,
    AD_USER_GROUP: import.meta.env.VITE_AD_USER_GROUP,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    DMA_URL: import.meta.env.VITE_DMA_URL,
    DMA_FORMULATION_IMPORT_PATH: import.meta.env.VITE_DMA_FORMULATION_IMPORT_PATH,
    LOGROCKET_TOKEN: import.meta.env.VITE_LOGROCKET_TOKEN,
});

class EnvironmentVariablesError extends Error {
    name = "EnvironmentVariablesError";

    constructor(errors: Record<string, string[]>) {
        const message = Object.entries(errors)
            .map(([key, errors]) => `${key}: ${errors.join(", ")}`)
            .join("\n");

        super(`\n\nErrors while validating environment variables:\n\n${message}\n\n`);
    }
}

if (result.success) {
    config = result.data;
} else {
    const errors = result.error.flatten();
    throw new EnvironmentVariablesError(errors.fieldErrors);
}

export { config };
