import { ReactNode, useState } from "react";

import { CheckIcon, CloseIcon } from "components/common/Icons";
import { Dialog } from "components/common/dialog/Dialog";
import { DialogContentWidth } from "components/common/dialog/Dialog/DialogContent/DialogContent";
import { Button } from "components/common/ui/Button";

type OnConfirmFn = (() => void) | (() => Promise<void>);
type OnCancelFn = (() => void) | (() => Promise<void>);

interface Props {
    title: string;
    content?: ReactNode;
    trigger: ReactNode;
    onConfirm: OnConfirmFn;
    onCancel?: OnCancelFn;
    confirmText?: string;
    cancelText?: string;
    width?: DialogContentWidth;
}

const DialogConfirm = (props: Props) => {
    const { onConfirm, onCancel } = props;
    const [open, setOpen] = useState(false);

    const handleConfirm = async () => {
        await onConfirm();
        setOpen(false);
    };

    const handleCancel = async () => {
        await onCancel?.();
        setOpen(false);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <Dialog.Trigger>{props.trigger}</Dialog.Trigger>
            <Dialog.Content width={props.width ?? "sm"}>
                <Dialog.Content.Title>{props.title}</Dialog.Content.Title>
                {props.content && <Dialog.Content.Body>{props.content}</Dialog.Content.Body>}
                <Dialog.Content.Actions>
                    <Button variant="primary" onClick={handleConfirm} IconLeading={CheckIcon}>
                        {props.confirmText ?? "Yes, confirm"}
                    </Button>
                    <Button onClick={handleCancel} IconLeading={CloseIcon}>
                        {props.cancelText ?? "No, cancel"}
                    </Button>
                </Dialog.Content.Actions>
            </Dialog.Content>
        </Dialog>
    );
};

export { DialogConfirm };
export type {
    Props as DialogConfirmProps,
    OnConfirmFn as DialogConfirmOnConfirmFn,
    OnCancelFn as DialogConfirmOnCancelFn,
};
