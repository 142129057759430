import styled from "styled-components";

import logoUrl from "components/layout/Sidebar/logo.png";

const SidebarLogoStyled = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;

    padding: 1.25rem;
`;

const Logo = styled.img`
    height: 3.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const LogoName = styled.div`
    color: ${({ theme }) => theme.color.text.dark};
    font-weight: 500;

    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0.0375rem;
`;

const SidebarLogo = () => {
    return (
        <SidebarLogoStyled>
            <Logo src={logoUrl} alt="logo" />
            <LogoName>
                <div>Integrated</div>
                <div>Formulation</div>
                <div>System</div>
            </LogoName>
        </SidebarLogoStyled>
    );
};

export { SidebarLogo };
