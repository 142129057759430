import styled from "styled-components";

export const SidebarLinkItemIcon = styled.div`
    flex-shrink: 0;

    width: 1.5rem;
    margin-right: 1rem;

    text-align: center;
`;
