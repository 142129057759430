import { CompositionDto } from "@api/composition/dto/composition.dto";
import { IngredientCreateDto } from "@api/ingredient/dto/ingredient-create.dto";
import { IngredientDetailDto } from "@api/ingredient/dto/ingredient-detail.dto";
import { IngredientDictionariesDto } from "@api/ingredient/dto/ingredient-dictionaries.dto";
import { IngredientUpdateDto } from "@api/ingredient/dto/ingredient-update.dto";
import { IngredientVersionInfoDto } from "@api/ingredient-version/dto/ingredient-version-info.dto";

import { api as baseApi } from "store/api";
import {
    INGREDIENT_CACHE_TYPE,
    INGREDIENT_CACHE_LIST_ID,
    PROJECT_CACHE_TYPE,
    PROJECT_CACHE_LIST_ID,
} from "store/services/services.tags";
import { tagsForOptionalIds } from "store/services/services.utils";
import { ApiRequestBody, ApiResponse } from "types/api.types";

const BASE_PATH = "ingredients";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [INGREDIENT_CACHE_TYPE, PROJECT_CACHE_TYPE],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getIngredients: builder.query<ApiResponse<IngredientDetailDto>[], void>({
                query: () => BASE_PATH,
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: INGREDIENT_CACHE_TYPE, id } as const)),
                              { type: INGREDIENT_CACHE_TYPE, id: INGREDIENT_CACHE_LIST_ID },
                              ...tagsForOptionalIds(
                                  PROJECT_CACHE_TYPE,
                                  result.map(({ projectId }) => projectId)
                              ),
                          ]
                        : [],
            }),
            getIngredient: builder.query<ApiResponse<IngredientDetailDto>, number>({
                query: (id) => `${BASE_PATH}/${id}`,
                providesTags: (result) =>
                    result
                        ? [
                              { type: INGREDIENT_CACHE_TYPE, id: result.id },
                              ...tagsForOptionalIds(PROJECT_CACHE_TYPE, [result.projectId]),
                          ]
                        : [],
            }),
            getIngredientDictionaries: builder.query<ApiResponse<IngredientDictionariesDto>, void>({
                query: () => `${BASE_PATH}/dictionaries`,
                providesTags: (result) =>
                    result
                        ? [
                              ...Object.keys(result.projectId).map((id) => ({ type: PROJECT_CACHE_TYPE, id } as const)),
                              { type: PROJECT_CACHE_TYPE, id: PROJECT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            getIngredientCompositions: builder.query<ApiResponse<CompositionDto>[], number>({
                query: (id) => `${BASE_PATH}/${id}/compositions`,
                providesTags: (result, error, ingredientId) =>
                    result ? [{ type: INGREDIENT_CACHE_TYPE, id: ingredientId }] : [],
            }),
            getIngredientVersions: builder.query<ApiResponse<IngredientVersionInfoDto>[], number>({
                query: (id) => `${BASE_PATH}/${id}/versions`,
                providesTags: (result, error, ingredientId) =>
                    result ? [{ type: INGREDIENT_CACHE_TYPE, id: ingredientId }] : [],
            }),
            createIngredient: builder.mutation<ApiResponse<IngredientDetailDto>, ApiRequestBody<IngredientCreateDto>>({
                query: (body) => ({
                    url: `${BASE_PATH}`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: (result) =>
                    result
                        ? [
                              { type: INGREDIENT_CACHE_TYPE, id: result.id },
                              { type: INGREDIENT_CACHE_TYPE, id: INGREDIENT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            updateIngredient: builder.mutation<
                ApiResponse<IngredientDetailDto>,
                ApiRequestBody<{ id: number; body: IngredientUpdateDto }>
            >({
                query: (args) => ({
                    url: `${BASE_PATH}/${args.id}`,
                    method: "PUT",
                    body: args.body,
                }),
                invalidatesTags: (result) => (result ? [{ type: INGREDIENT_CACHE_TYPE, id: result.id }] : []),
            }),
        }),
    });

export const {
    useGetIngredientsQuery,
    useGetIngredientQuery,
    useCreateIngredientMutation,
    useGetIngredientDictionariesQuery,
    useUpdateIngredientMutation,
    useGetIngredientCompositionsQuery,
    useGetIngredientVersionsQuery,
} = api;
