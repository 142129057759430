import { Dialog as _Dialog, DialogProps } from "components/common/dialog/Dialog/Dialog";
import { DialogAction } from "components/common/dialog/Dialog/DialogAction";
import { DialogContent } from "components/common/dialog/Dialog/DialogContent";
import { DialogTrigger } from "components/common/dialog/Dialog/DialogTrigger";

const Dialog = _Dialog as typeof _Dialog & {
    Action: typeof DialogAction;
    Content: typeof DialogContent;
    Trigger: typeof DialogTrigger;
};

Dialog.Action = DialogAction;
Dialog.Content = DialogContent;
Dialog.Trigger = DialogTrigger;

export { Dialog };
export type { DialogProps };
