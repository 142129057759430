import { DefaultTheme } from "styled-components";

import { colors } from "styles/colors";
import { NestedPaths } from "types/path.types";

const lightTheme: DefaultTheme["color"] = {
    border: colors.grey[200],
    overlay: "hsla(208, 31%, 26%, 0.8)",
    background: {
        white: "hsl(0, 0%, 100%)",
        light: colors.grey[50],
        main: colors.grey[100],
        dark: colors.grey[200],
    },
    text: {
        light: colors.grey[500],
        main: colors.grey[700],
        dark: colors.grey[900],
        disabled: colors.grey[400],
        headings: {
            light: colors.grey[500],
            main: colors.grey[700],
        },
    },
    icon: {
        light: colors.grey[400],
        main: colors.grey[500],
        dark: colors.grey[700],
        disabled: colors.grey[300],
    },
    primary: {
        light: colors.primary[50],
        main: colors.primary[900],
        icon: {
            main: colors.primary[500],
            light: colors.primary[300],
        },
        text: colors.primary[900],
        background: {
            main: colors.primary[900],
            light: colors.primary[700],
        },
        border: {
            main: colors.primary[300],
            light: colors.primary[100],
        },
    },
    danger: {
        background: colors.danger[100],
        icon: colors.danger[500],
        text: colors.danger[900],
        border: colors.danger[500],
    },
    warning: {
        background: colors.warning[100],
        icon: colors.warning[500],
        text: colors.warning[900],
        border: colors.warning[500],
    },
    success: {
        background: colors.success[100],
        icon: colors.success[500],
        text: colors.success[900],
        border: colors.success[500],
    },
    info: {
        background: colors.info[100],
        icon: colors.info[500],
        text: colors.info[900],
        border: colors.info[500],
    },
    logo: {
        icon: colors.primary[900],
        background: colors.primary[100],
    },
};

export const theme: DefaultTheme = {
    boxShadow: {
        overlay: "0 8px 32px hsla(0, 0%, 0%, 0.14)",
    },
    borderRadius: {
        sm: "0.25rem",
        md: "0.5rem",
        lg: "0.75rem",
    },
    breakpoints: {
        xs: "0px",
        sm: "576px",
        md: "768px",
        lg: "992px",
        xl: "1200px",
        xxl: "1568px",
    },
    color: lightTheme,
    font: {
        size: {
            body: {
                sm: "0.875rem", // 14px
                md: "1rem", // 16px
                lg: "1.125rem", // 18px
            },
            button: "0.875rem", // 14px,
            caption: {
                sm: "0.625rem", // 12px
                md: "0.75rem", // 10px
            },
            heading: {
                sm: "1.125rem", // 18px
                md: "1.375rem", // 22px
                lg: "1.625rem", // 26px
            },
        },
        lineHeight: {
            body: {
                sm: "1.25rem", // 20px
                md: "1.625rem", // 26px
                lg: "1.875rem", // 30px
            },
            button: "1rem", // 16px,
            caption: {
                sm: "0.75rem", // 12px
                md: "0.9375rem", // 15px
            },
            heading: {
                sm: "1.625rem", // 26px
                md: "1.9375rem", // 31px
                lg: "2rem", // 32px
            },
        },
    },
    zIndex: {
        dialog: 50,
        inputPortal: 100,
    },
};

type ColorPath = NestedPaths<DefaultTheme["color"]>;
export const color = (colorPath: ColorPath): string => {
    return colorPath.split(".").reduce<any>((acc, curr) => acc[curr], theme.color);
};
