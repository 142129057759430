import { Required } from "components/common/form/Input/Date/Date.stories";
import { Breadcrumbs } from "components/common/ui/Breadcrumbs";
import { useEntity } from "components/layout/PathBreadcrumbs/path-breadcrumbs.hooks";
import { PathBreadcrumbsItemBaseProps } from "components/layout/PathBreadcrumbs/path-breadcrumbs.types";

type Props = PathBreadcrumbsItemBaseProps & Required<Pick<PathBreadcrumbsItemBaseProps, "relatedEntity" | "params">>;

const PathBreadcrumbsEntityItem = (props: Props) => {
    const title = useEntity(props.relatedEntity, props.params);
    return <Breadcrumbs.Item path={props.path}>{title}</Breadcrumbs.Item>;
};

export { PathBreadcrumbsEntityItem };
