import { HTMLAttributes } from "react";
import styled from "styled-components";

import { IconComponentType } from "components/common/Icons";

type HTMLElement = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span";
type Size = "sm" | "md" | "lg";

interface Props extends Pick<HTMLAttributes<any>, "className" | "title" | "children"> {
    as?: HTMLElement;
    size?: Size;
    Icon?: IconComponentType;
}

const HeadlineStyled = styled.h2<Required<Pick<Props, "size">>>`
    color: ${({ theme }) => theme.color.text.headings.main};

    font-size: ${({ theme, size }) => theme.font.size.heading[size]};
    font-weight: ${({ size }) => (size === "lg" ? 600 : 500)};
    line-height: ${({ theme, size }) => theme.font.lineHeight.heading[size]};

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & > svg {
        margin-right: 1rem;
        color: ${({ theme }) => theme.color.icon.main};
    }
`;

const Headline = ({ size = "md", ...props }: Props) => {
    const { Icon } = props;
    return (
        <HeadlineStyled as={props.as} size={size} className={props.className}>
            {Icon && <Icon />}
            {props.children}
        </HeadlineStyled>
    );
};

export { Headline };
export type { Props as HeadlineProps, Size as HeadlineSize };
