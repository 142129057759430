import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { Client } from "@microsoft/microsoft-graph-client";
import {
    AuthCodeMSALBrowserAuthenticationProvider,
    AuthCodeMSALBrowserAuthenticationProviderOptions,
} from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";

import { authConfiguration } from "./configuration";

let api: PublicClientApplication;

/**
 * Use `useMsal` hook whenever possible instead of this method
 */
const getMsalInstance = (): PublicClientApplication => {
    if (!api) {
        api = new PublicClientApplication(authConfiguration);
    }
    return api;
};

let client: Client;
const getMicrosoftGraphClient = (): Client => {
    if (!client) {
        const msalInstance = getMsalInstance();
        const accounts = msalInstance.getAllAccounts();

        const options: AuthCodeMSALBrowserAuthenticationProviderOptions = {
            account: accounts[0],
            interactionType: InteractionType.Redirect,
            scopes: ["user.read"],
        };

        const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msalInstance, options);

        client = Client.initWithMiddleware({
            debugLogging: true,
            authProvider,
        });
    }
    return client;
};

export { getMsalInstance, getMicrosoftGraphClient };
