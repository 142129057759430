import * as RadixDialog from "@radix-ui/react-dialog";
import { HTMLAttributes } from "react";

type Props = Pick<HTMLAttributes<any>, "children"> & Pick<RadixDialog.DialogProps, "modal" | "open" | "onOpenChange">;

const Dialog = (props: Props) => {
    return <RadixDialog.Root {...props} />;
};

export { Dialog };
export type { Props as DialogProps };
