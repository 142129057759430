import * as RadixDialog from "@radix-ui/react-dialog";
import { HTMLAttributes } from "react";
import styled from "styled-components";

type Width = "sm" | "md";
interface Props extends Pick<HTMLAttributes<any>, "children"> {
    width?: Width;
}

const DialogOverlay = styled(RadixDialog.Overlay)`
    position: fixed;
    inset: 0;
    z-index: ${({ theme }) => theme.zIndex.dialog - 1};

    backdrop-filter: blur(2px);
    background-color: ${({ theme }) => theme.color.overlay};

    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    @keyframes overlayShow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const DialogContentStyled = styled(RadixDialog.Content)<Pick<Props, "width">>`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: ${({ theme }) => theme.zIndex.dialog};

    transform: translate(-50%, -50%);
    width: ${({ width }) => (width === "md" ? "48rem" : "36rem")};
    max-width: 85vw;
    max-height: 85vh;
    overflow: auto;

    background-color: ${({ theme }) => theme.color.background.white};
    border-radius: 0.5rem;
    box-shadow: ${({ theme }) => theme.boxShadow.overlay};

    &:focus {
        outline: none;
    }

    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
    @keyframes contentShow {
        from {
            opacity: 0;
            transform: translate(-50%, -48%) scale(0.96);
        }
        to {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }
`;

const DialogContent = ({ width = "md", ...props }: Props) => {
    return (
        <RadixDialog.Portal>
            <DialogOverlay />
            <DialogContentStyled width={width}>{props.children}</DialogContentStyled>
        </RadixDialog.Portal>
    );
};

export { DialogContent };
export type { Props as DialogContentProps, Width as DialogContentWidth };
