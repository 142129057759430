import {
    DialogContent as _DialogContent,
    DialogContentProps,
} from "components/common/dialog/Dialog/DialogContent/DialogContent";
import { DialogContentActions } from "components/common/dialog/Dialog/DialogContent/DialogContentActions";
import { DialogContentBody } from "components/common/dialog/Dialog/DialogContent/DialogContentBody";
import { DialogContentTitle } from "components/common/dialog/Dialog/DialogContent/DialogContentTitle";

const DialogContent = _DialogContent as typeof _DialogContent & {
    Actions: typeof DialogContentActions;
    Body: typeof DialogContentBody;
    Title: typeof DialogContentTitle;
};

DialogContent.Actions = DialogContentActions;
DialogContent.Body = DialogContentBody;
DialogContent.Title = DialogContentTitle;

export { DialogContent };
export type { DialogContentProps };
