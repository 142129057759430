/*
  User tags
*/
export const USER_CACHE_TYPE = "users" as const;
export const USER_PROJECT_ROLE_CACHE_TYPE = "userProjectRole" as const;

/*
  Project tags
*/
export const PROJECT_CACHE_TYPE = "projects" as const;
export const PROJECT_CACHE_LIST_ID = "PROJECTS_LIST" as const;

/*
  Project roles tags
*/
export const PROJECT_USER_ROLES_CACHE_TYPE = "projectUserRoles" as const;

/*
  Product types
*/
export const PRODUCT_CACHE_TYPE = "products" as const;
export const PRODUCT_CACHE_LIST_ID = "PRODUCTS_LIST" as const;

/*
  Ingredient types 
*/
export const INGREDIENT_CACHE_TYPE = "ingredients" as const;
export const INGREDIENT_CACHE_LIST_ID = "INGREDIENTS_LIST" as const;

/*
    UserAPIToken types
*/
export const USER_API_TOKEN_CACHE_TYPE = "userapitokens" as const;

export type CacheTagType =
    | typeof USER_CACHE_TYPE
    | typeof PROJECT_CACHE_TYPE
    | typeof PROJECT_USER_ROLES_CACHE_TYPE
    | typeof PRODUCT_CACHE_TYPE
    | typeof INGREDIENT_CACHE_TYPE
    | typeof USER_API_TOKEN_CACHE_TYPE;
