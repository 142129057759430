import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

import { theme } from "styles/theme";

interface Props {
    className?: string;
    size?: FontAwesomeIconProps["size"];
}

const Loader = ({ size = "2xl", ...props }: Props) => {
    return (
        <FontAwesomeIcon
            {...props}
            id="loader"
            icon={faSpinnerThird}
            spin={true}
            size={size}
            style={{
                ["--fa-animation-duration" as any]: "1s",
                ["--fa-primary-color" as any]: theme.color.primary.background.main,
                ["--fa-secondary-color" as any]: theme.color.background.dark,
            }}
        />
    );
};

export { Loader };
