import { ReactNode } from "react";
import styled from "styled-components";

import { PathBreadcrumbs } from "components/layout/PathBreadcrumbs";
import { Sidebar } from "components/layout/Sidebar";
import { MAIN_CONTENT_ID } from "components/layout/layout.consts";

interface Props {
    children: ReactNode;
}

const LayoutStyled = styled.div`
    display: flex;
    height: 100vh;
    overflow: hidden;

    background: ${({ theme }) => theme.color.background.light};
`;

const ContentWithBreadcrumbs = styled.div`
    height: 100%;
    overflow: auto;
    position: relative;

    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    padding: 2rem 2rem;
`;

const Content = styled.div`
    flex: auto;
`;

const Layout = (props: Props) => {
    const { children, ...extraProps } = props;

    return (
        <LayoutStyled {...extraProps}>
            <Sidebar />
            <ContentWithBreadcrumbs id={MAIN_CONTENT_ID}>
                <PathBreadcrumbs />
                <Content>{children}</Content>
            </ContentWithBreadcrumbs>
        </LayoutStyled>
    );
};

export { Layout };
