import { MsalProvider } from "@azure/msal-react";
import { PropsWithChildren, useMemo } from "react";

import { getMsalInstance } from "store/active-directory";

export const AuthProvider = ({ children }: PropsWithChildren): JSX.Element => {
    const pca = useMemo(() => {
        return getMsalInstance();
    }, []);

    return <MsalProvider instance={pca}>{children}</MsalProvider>;
};
