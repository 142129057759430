import { up } from "styled-breakpoints";
import styled, { css, Breakpoints } from "styled-components";

type ResponsiveSpacing = string | Partial<Record<Breakpoints, string>>;
type SpacerProps = {
    m?: ResponsiveSpacing;
    p?: ResponsiveSpacing;
    mx?: ResponsiveSpacing;
    my?: ResponsiveSpacing;
    mb?: ResponsiveSpacing;
    px?: ResponsiveSpacing;
    py?: ResponsiveSpacing;
    pb?: ResponsiveSpacing;
};

const responsiveValues = (breakpoint: Breakpoints) => (props: SpacerProps) =>
    css`
        padding: ${props.p && (typeof props.p === "string" ? props.p : props.p[breakpoint])};
        padding-left: ${props.px && (typeof props.px === "string" ? props.px : props.px[breakpoint])};
        padding-right: ${props.px && (typeof props.px === "string" ? props.px : props.px[breakpoint])};
        padding-top: ${props.py && (typeof props.py === "string" ? props.py : props.py[breakpoint])};
        padding-bottom: ${props.py && (typeof props.py === "string" ? props.py : props.py[breakpoint])};
        padding-bottom: ${props.pb && (typeof props.pb === "string" ? props.pb : props.pb[breakpoint])};

        margin: ${props.m && (typeof props.m === "string" ? props.m : props.m[breakpoint])};
        margin-left: ${props.mx && (typeof props.mx === "string" ? props.mx : props.mx[breakpoint])};
        margin-right: ${props.mx && (typeof props.mx === "string" ? props.mx : props.mx[breakpoint])};
        margin-top: ${props.my && (typeof props.my === "string" ? props.my : props.my[breakpoint])};
        margin-bottom: ${props.my && (typeof props.my === "string" ? props.my : props.my[breakpoint])};
        margin-bottom: ${props.mb && (typeof props.mb === "string" ? props.mb : props.mb[breakpoint])};
    `;
export const Spacer = styled.div<SpacerProps>`
    padding: ${(props) => props.p ?? ""};
    padding-left: ${(props) => props.px ?? ""};
    padding-right: ${(props) => props.px ?? ""};
    padding-top: ${(props) => props.py ?? ""};
    padding-bottom: ${(props) => props.py ?? ""};
    padding-bottom: ${(props) => props.pb ?? ""};

    margin: ${(props) => props.m ?? ""};
    margin-left: ${(props) => props.mx ?? ""};
    margin-right: ${(props) => props.mx ?? ""};
    margin-top: ${(props) => props.my ?? ""};
    margin-bottom: ${(props) => props.my ?? ""};
    margin-bottom: ${(props) => props.mb ?? ""};

    ${up("sm")} {
        ${responsiveValues("sm")}
    }

    ${up("md")} {
        ${responsiveValues("md")}
    }

    ${up("lg")} {
        ${responsiveValues("lg")}
    }

    ${up("xl")} {
        ${responsiveValues("xl")}
    }

    ${up("xxl")} {
        ${responsiveValues("xxl")}
    }
`;
