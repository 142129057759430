import * as RadixDialog from "@radix-ui/react-dialog";
import { HTMLAttributes } from "react";

type Props = Pick<HTMLAttributes<any>, "children">;

const DialogActionClose = (props: Props) => {
    return <RadixDialog.Close asChild={true}>{props.children}</RadixDialog.Close>;
};

export { DialogActionClose };
export type { Props as DialogActionCloseProps };
