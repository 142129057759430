import { AccountInfo, EventMessage, EventType, IPublicClientApplication } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { useEffect } from "react";

import { AdUser, fetchCurrentUser } from "store/active-directory";
import { useUpsertUserMutation } from "store/services/user.service";
import { isEmail } from "utils/string.utils";

export type AuthUser = AccountInfo;
export const useAuthUser = (): AuthUser | null => {
    const { accounts } = useMsal();
    return useAccount(accounts[0] ?? null);
};

export const useUpsertAppUser = (instance: IPublicClientApplication) => {
    const [upsertUser] = useUpsertUserMutation();

    const handleUpsertUser = async (user: AdUser) => {
        let email: string | undefined = user.mail;
        if (!email && isEmail(user.userPrincipalName)) {
            email = user.userPrincipalName;
        }

        return upsertUser({
            id: user.id,
            firstName: user.givenName,
            lastName: user.surname,
            email,
        }).unwrap();
    };

    const handleMessage = async (message: EventMessage) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
            // upon successful login:
            // - fetch current user from Microsoft Graph
            // - create / update the user in the database
            try {
                const currentUser = await fetchCurrentUser();
                await handleUpsertUser(currentUser);
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        }
    };

    useEffect(() => {
        const callbackId = instance.addEventCallback(handleMessage);

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
