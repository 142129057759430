import { lazy } from "react";

// Application pages are lazy loaded for two main reasons:
// 1) bundle chucking - all pages are not dependent on dependencies of all pages
// 2) circumventing circular dependencies - breaks the circle by using dynamic import (issue caused by importing routes in page files)

// Project pages
export const ProjectsPage = lazy(() => import("components/pages/projects/ProjectsPage"));
export const CreateProjectPage = lazy(() => import("components/pages/projects/CreateProjectPage"));
export const EditProjectPage = lazy(() => import("components/pages/projects/EditProjectPage"));
export const ProjectDetailPage = lazy(() => import("components/pages/projects/ProjectDetailPage"));

export const ProjectUsersPage = lazy(() => import("components/pages/projects/ProjectUsersPage"));
export const ProjectUsersEditPage = lazy(() => import("components/pages/projects/ProjectUsersEditPage"));
export const ProjectIngredientsPage = lazy(() => import("components/pages/projects/ProjectIngredientsPage"));

// Product pages
export const ProductDetailPage = lazy(() => import("components/pages/products/ProductDetailPage"));
export const CreateProductPage = lazy(() => import("components/pages/products/CreateProductPage"));
export const EditProductPage = lazy(() => import("components/pages/products/EditProductPage/EditProductPage"));
export const ProductHistoryPage = lazy(() => import("components/pages/products/ProductHistoryPage"));

// Ingredient pages
export const IngredientsPage = lazy(() => import("components/pages/ingredients/IngredientsPage"));
export const CreateIngredientPage = lazy(() => import("components/pages/ingredients/CreateIngredientPage"));
export const EditIngredientPage = lazy(() => import("components/pages/ingredients/EditIngredientPage"));
export const IngredientDetailPage = lazy(() => import("components/pages/ingredients/IngredientDetailPage"));
export const IngredientHistoryPage = lazy(() => import("components/pages/ingredients/IngredientHistoryPage"));

// User pages
export const UsersPage = lazy(() => import("components/pages/users/UsersPage"));
export const UserProfilePage = lazy(() => import("components/pages/users/UserProfilePage"));

// Import pages
export const ImportProductPage = lazy(() => import("components/pages/import/ImportProductPage"));

// Error pages
export const NotFoundPage = lazy(() => import("components/pages/errors/NotFoundPage"));
