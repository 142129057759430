import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";

import { getMicrosoftGraphClient } from "./api";
import type { AdUser } from "./types";

import { config } from "config";

const userProjection = ["givenName", "surname", "displayName", "id", "mail", "userPrincipalName"];

const fetchCurrentUser = async (): Promise<AdUser> => {
    const client = getMicrosoftGraphClient();
    const user = await client.api("/me").select(userProjection).get();
    return user satisfies AdUser;
};

/**
 * Method for fetching users who belongs to a given group
 *
 * More information: https://learn.microsoft.com/en-us/graph/api/group-list-members?view=graph-rest-1.0&tabs=javascript
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchUsersFromGroup = async (): Promise<AdUser[]> => {
    const client = getMicrosoftGraphClient();
    const groupId = config.AD_USER_GROUP;
    const users = await client
        .api(`/groups/${groupId}/members`)
        .top(999)
        .select(userProjection)
        .get()
        .then((response) => response.value);
    return users satisfies AdUser[];
};

type ApiError = FetchBaseQueryError | SerializedError;
type UseAllAvailableUsers = {
    data?: AdUser[];
    isFetching: boolean;
    isError: boolean;
    error?: ApiError;
};

const useAllAvailableUsers = (): UseAllAvailableUsers => {
    const [data, setData] = useState<AdUser[]>();
    const [error, setError] = useState<Error>();
    const [isFetching, setIsFetching] = useState<boolean>(true);

    useEffect(() => {
        fetchUsersFromGroup()
            .then((users) => setData(users))
            .catch((error) => setError(error))
            .finally(() => setIsFetching(false));
    }, []);

    return {
        data,
        error,
        isError: error !== undefined ?? false,
        isFetching,
    };
};

export { useAllAvailableUsers, fetchCurrentUser };
export type { ApiError };
