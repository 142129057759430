import { MouseEventHandler, useEffect, useState } from "react";

import type { ButtonProps } from "components/common/ui/Button/Button";

export const useLoading = (props: ButtonProps) => {
    const [isLoading, setIsLoading] = useState(props.isLoading);

    useEffect(() => {
        setIsLoading(props.isLoading);
    }, [props.isLoading]);

    const handleAsyncClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setIsLoading(true);
        await props.onClick?.(event);
        setIsLoading(false);
    };

    return { isLoading, handleAsyncClick, disabled: props.disabled || isLoading };
};
