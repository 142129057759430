import { matchPath } from "react-router-dom";

import {
    EntityBreadcrumbs,
    Params,
    PartialPathsState,
    PathMatch,
    PathSegmentKeyword,
} from "components/layout/PathBreadcrumbs/path-breadcrumbs.types";
import { allRoutePaths } from "components/routes/routes";

const entities: EntityBreadcrumbs[] = ["projects", "products", "ingredients"];
const isEntity = (value: string): value is EntityBreadcrumbs => {
    return entities.includes(value as any);
};

// By default, if the app handles a route in a format `/entity/:parameter`,
// then the paths `/projects/1` and `/projects/create` get matched, but only from
// the path `/projects/1` we want to extract the parameter.
// At the moment in the application routes, only the `PathSegmentKeyword` union is allowed in places
// where a parameter can be present, so the breadcrumbs logic is configured
// to not extract the parameter from such routes.
const pathSegmentKeywords: PathSegmentKeyword[] = ["create", "edit"];
const isPathSegmentKeyword = (value?: string): value is PathSegmentKeyword => {
    return pathSegmentKeywords.includes(value as any);
};

const extractEntity = (path: string): EntityBreadcrumbs | undefined => {
    const patternLastSegment = path.split("/").at(-1);
    if (!patternLastSegment) return undefined;

    return isEntity(patternLastSegment) ? patternLastSegment : undefined;
};

const isParamSegment = (matchedRoute: PathMatch) => {
    const patternLastSegment = matchedRoute.pattern.path.split("/").at(-1);
    if (!patternLastSegment) return false;

    return patternLastSegment.at(0) === ":";
};

const extractParams = (matchedRoute: PathMatch): Params | undefined => {
    const pathSegments = matchedRoute.pattern.path.split("/");
    const paramPathSegments = pathSegments.filter((pathSegment) => pathSegment.at(0) === ":");
    const paramNames = paramPathSegments.map((paramPathSegment) => paramPathSegment.slice(1));

    const params = paramNames.reduce<Params>((acc, param) => ({ ...acc, [param]: matchedRoute.params[param] }), {});
    return params;
};

/**
 * Builds an array of partial paths from a full path (with metadata).
 *
 * @example
 * const items = getItems(['projects','1','product']")
 * items[0].path = "/projects"
 * items[1].path = "/projects/1"
 * items[2].path = "/projects/1/products"
 *
 * @param pathSegments
 */
export const getItems = (pathSegments: string[]) =>
    pathSegments.reduce<PartialPathsState>(
        (acc, pathSegment) => {
            const { previousPartialPath, previousRelatedEntity } = acc;
            const partialPath = [previousPartialPath, pathSegment].join("/");

            // Find a defined app route that matches current partial paths
            const routeMatchingPath = allRoutePaths.find((route) => matchPath(route, partialPath));
            const pathMatch = routeMatchingPath ? (matchPath(routeMatchingPath, partialPath) as PathMatch) : null;

            // Check if the last path segment is an entity reference
            const relatedEntity = extractEntity(partialPath) ?? previousRelatedEntity;

            let path: string | undefined;
            let params: Params | undefined;
            if (pathMatch) {
                // Provide path to breadcrumbs only if matching route exists
                path = partialPath;

                // If the last path segment contains a parameter
                // and the path contains a related entity in a previous segment
                // extract the params from the path
                // parameters will be used in querying the entity data
                const pathLastSegment = partialPath.split("/").at(-1);
                if (previousRelatedEntity && isParamSegment(pathMatch) && !isPathSegmentKeyword(pathLastSegment)) {
                    params = extractParams(pathMatch);
                }
            }

            const value = pathSegment;
            return {
                items: [...acc.items, { value, path, relatedEntity, params }],
                previousPartialPath: partialPath,
                previousRelatedEntity: relatedEntity,
            };
        },
        { items: [], previousPartialPath: "", previousRelatedEntity: undefined }
    ).items;
