import { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

import { PageLoader } from "components/common/ui/Loader/PageLoader";
import { Layout } from "components/layout/Layout";
import { ImportProductPage, NotFoundPage } from "components/pages";
import { importPaths, routes } from "components/routes/routes";

export const AppRoutes = () => {
    return (
        <Routes>
            <Route index element={<Navigate to={routes.projects.path} replace />} />
            <Route
                element={
                    <Suspense fallback={<PageLoader />}>
                        <Outlet />
                    </Suspense>
                }
            >
                <Route path={importPaths.importProduct} element={<ImportProductPage />} />
            </Route>
            <Route
                element={
                    <Layout>
                        {/* Used because app pages are lazy loaded */}
                        <Suspense fallback={<PageLoader />}>
                            <Outlet />
                        </Suspense>
                    </Layout>
                }
            >
                {Object.values(routes).map(({ path, Page }) => (
                    <Route key={path} path={path} element={<Page />} />
                ))}
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    );
};
