import { Link } from "react-router-dom";
import styled from "styled-components";

import { IconComponentType, IngredientIcon, ProductIcon, ProjectIcon } from "components/common/Icons";
import { SidebarLinkItem } from "components/layout/Sidebar/SidebarLinkItem";
import { sidebarItemCSS } from "components/layout/Sidebar/SidebarLinkItem/sidebar-link-item.styles";
import { SidebarSectionTitle } from "components/layout/Sidebar/SidebarSectionTitle";
import { useAppSelector } from "store/hooks";
import { RecentEntity } from "store/slices/recent-entity.slice";

const RecentEntityLink = styled(Link)`
    ${sidebarItemCSS};
`;

const entityIconMap: Record<RecentEntity, IconComponentType> = {
    project: ProjectIcon,
    product: ProductIcon,
    ingredient: IngredientIcon,
};

const SidebarRecentSection = () => {
    const items = useAppSelector((state) => state.recentEntity.items);

    if (items.length === 0) return null;

    return (
        <div>
            <SidebarSectionTitle>Recently opened</SidebarSectionTitle>
            {items.map((item) => {
                const Icon = entityIconMap[item.entity];
                return (
                    <RecentEntityLink key={item.link} to={item.link} title={item.name}>
                        <SidebarLinkItem.Icon>
                            <Icon size="lg" />
                        </SidebarLinkItem.Icon>
                        <SidebarLinkItem.Content>{item.name}</SidebarLinkItem.Content>
                    </RecentEntityLink>
                );
            })}
        </div>
    );
};

export { SidebarRecentSection };
