import { ReactNode } from "react";
import styled from "styled-components";

import { NextIcon } from "components/common/Icons";
import { BreadcrumbsItemLink } from "components/common/ui/Breadcrumbs/BreadcrumbsItemLink";
import { BreadcrumbsItemValue } from "components/common/ui/Breadcrumbs/BreadcrumbsItemValue";

interface Props {
    path?: string;
    children: ReactNode;
}

const BreadcrumbsItemStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-weight: 400;
    color: ${({ theme }) => theme.color.text.light};

    &:last-child {
        font-weight: 500;
        color: ${({ theme }) => theme.color.text.main};
    }

    // Hide the NextIcon for the breadcrumbs item
    &:first-child > svg:first-child {
        display: none;
    }
`;

const BreadcrumbsItem = (props: Props) => {
    const { path } = props;

    let content = <BreadcrumbsItemValue>{props.children}</BreadcrumbsItemValue>;
    if (path) {
        content = <BreadcrumbsItemLink to={path}>{content}</BreadcrumbsItemLink>;
    }

    return (
        <BreadcrumbsItemStyled>
            <NextIcon />
            {content}
        </BreadcrumbsItemStyled>
    );
};

export { BreadcrumbsItem };
