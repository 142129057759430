import { ButtonHTMLAttributes, ComponentType, forwardRef, ReactNode } from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import { IconComponentType } from "components/common/Icons";
import { useLoading } from "components/common/ui/Button/button.hooks";
import { Loader } from "components/common/ui/Loader";

type Size = "md" | "sm";
type Variant = "primary" | "default";

interface Props
    extends Pick<ButtonHTMLAttributes<any>, "className" | "disabled" | "form" | "title" | "type" | "onClick"> {
    size?: Size;
    variant?: Variant;
    children: ReactNode;
    IconLeading?: IconComponentType;
    IconTrailing?: IconComponentType;
    isLoading?: boolean;
    // Overwrite base button HTML element (with Link for example)
    as?: any;
}

const sizeMdCSS = css`
    padding: 0 1.5rem;
    height: 3rem;
`;

const sizeSmCSS = css`
    padding: 0 1.25rem;
    height: 2.5rem;
`;

const sizeCSS: Record<Size, FlattenSimpleInterpolation> = {
    md: sizeMdCSS,
    sm: sizeSmCSS,
};

export const ButtonStyled = styled.button<Required<Pick<Props, "size" | "variant">>>`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${({ size }) => sizeCSS[size]};

    font-size: ${({ theme }) => theme.font.size.button};
    line-height: 1;
    letter-spacing: 0.025rem;
    font-weight: 500;

    background-color: ${({ theme, variant }) =>
        variant === "primary" ? theme.color.primary.background.main : theme.color.background.white};
    border: 1px solid ${({ theme, variant }) => (variant === "primary" ? "transparent" : theme.color.border)};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));

    color: ${({ theme, variant }) => (variant === "primary" ? theme.color.primary.light : theme.color.text.dark)};

    &:hover:enabled {
        background-color: ${({ theme, variant }) =>
            variant === "primary" ? theme.color.primary.background.light : theme.color.background.light};
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    & svg {
        font-size: 1rem;
        color: ${({ theme, variant }) =>
            variant === "primary" ? theme.color.primary.icon.light : theme.color.icon.main};
    }
`;

export const IconLeadingWrapper = styled.span`
    margin-left: -0.25rem;
    margin-right: 0.75rem;
`;

export const IconTrailingWrapper = styled.span`
    margin-left: 0.75rem;
    margin-right: -0.25rem;
`;

const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { children, size: _size, variant: _variant, as, IconLeading, IconTrailing, ...extraProps } = props;
    const size = _size ?? "md";
    const variant = _variant ?? "default";
    const loadingProps = useLoading(props);

    let ElementLeading: ComponentType | undefined = IconLeading;
    if (loadingProps.isLoading) {
        ElementLeading = Loader;
    }

    return (
        <ButtonStyled
            {...extraProps}
            as={as}
            size={size}
            variant={variant}
            disabled={loadingProps.disabled}
            onClick={loadingProps.handleAsyncClick}
            ref={ref}
        >
            {ElementLeading && (
                <IconLeadingWrapper>
                    <ElementLeading />
                </IconLeadingWrapper>
            )}
            {children}
            {IconTrailing && (
                <IconTrailingWrapper>
                    <IconTrailing />
                </IconTrailingWrapper>
            )}
        </ButtonStyled>
    );
});

Button.displayName = "Button";

export { Button };
export type { Props as ButtonProps, Variant as ButtonVariant };
