import styled from "styled-components";

import { SidebarLinkItem } from "components/layout/Sidebar/SidebarLinkItem";
import { SidebarLogo } from "components/layout/Sidebar/SidebarLogo";
import { SidebarRecentSection } from "components/layout/Sidebar/SidebarRecentSection";
import { SidebarUserSection } from "components/layout/Sidebar/SidebarUserSection";
import { sidebarRoutes } from "components/routes/routes";

const SidebarStyled = styled.div`
    flex: 0 0 auto;

    display: flex;
    flex-direction: column;
    width: 16.75rem;
    height: 100%;

    background: ${({ theme }) => theme.color.background.main};
`;

const SidebarContent = styled.div`
    height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 1.25rem 1.25rem 1.25rem;
`;

const Sidebar = () => {
    return (
        <SidebarStyled>
            <SidebarLogo />
            <SidebarContent>
                <div>
                    {Object.values(sidebarRoutes).map(({ path, label, Icon }) => (
                        <SidebarLinkItem key={path} to={path}>
                            <SidebarLinkItem.Icon>{Icon ? <Icon size="lg" /> : null}</SidebarLinkItem.Icon>
                            <SidebarLinkItem.Content>{label}</SidebarLinkItem.Content>
                        </SidebarLinkItem>
                    ))}
                    <SidebarRecentSection />
                </div>
                <SidebarUserSection />
            </SidebarContent>
        </SidebarStyled>
    );
};

export { Sidebar };
