import { FC, ReactNode } from "react";
import { only, up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import styled, { Breakpoints, css } from "styled-components";

import { Spacer } from "components/common/layout/Spacer";

type FlexValues = {
    flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
    flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-evenly" | "space-around";
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center";
    gap?: string;
};

type FlexProps = FlexValues & Partial<Record<Breakpoints, FlexValues>>;

const responsiveValues = (key: Breakpoints) => (props: FlexProps) =>
    css`
        flex-direction: ${props[key]?.flexDirection ?? ""};
        flex-wrap: ${props[key]?.flexWrap ?? ""};
        justify-content: ${props[key]?.justifyContent ?? ""};
        align-items: ${props[key]?.alignItems ?? ""};
        gap: ${props[key]?.gap ?? ""};
    `;

export const Flex = styled((props) => <Spacer {...props} />)<FlexProps>`
    display: flex;
    flex-direction: ${(props) => props.flexDirection ?? ""};
    flex-wrap: ${(props) => props.flexWrap ?? ""};
    justify-content: ${(props) => props.justifyContent ?? ""};
    align-items: ${(props) => props.alignItems ?? ""};
    gap: ${(props) => props.gap ?? ""};

    ${up("sm")} {
        ${responsiveValues("sm")}
    }

    ${up("md")} {
        ${responsiveValues("md")}
    }

    ${up("lg")} {
        ${responsiveValues("lg")}
    }

    ${up("xl")} {
        ${responsiveValues("xl")}
    }

    ${up("xxl")} {
        ${responsiveValues("xxl")}
    }
`;

type ResponsiveFlexProps = FlexProps & {
    breakpoint: ReturnType<typeof only>;
    children: ReactNode;
};
export const ResponsiveFlex: FC<ResponsiveFlexProps> = ({ breakpoint, children, ...props }) => {
    const showInFlexbox = useBreakpoint(breakpoint);
    if (showInFlexbox) {
        return <Flex {...props}>{children}</Flex>;
    }
    return <>{children}</>;
};
