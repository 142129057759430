import styled from "styled-components";

import { Flex } from "components/common/layout";
import { Loader } from "components/common/ui/Loader/Loader";
import { useDebounce } from "components/common/ui/Loader/loader.hooks";

const LoaderWrapper = styled(Flex)`
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

export const PageLoader = () => {
    const show = useDebounce();
    if (!show) return null;

    return (
        <LoaderWrapper>
            <Loader />
        </LoaderWrapper>
    );
};
