import { PermissionRoleType } from "@api/project-role/project-role.types";
import { UserCreateDto } from "@api/user/dto/user-create.dto";
import { UserProjectRoleDto } from "@api/user/dto/user-project-role";
import { UserDto } from "@api/user/dto/user.dto";

import { api as baseApi } from "store/api";
import {
    USER_CACHE_TYPE,
    USER_PROJECT_ROLE_CACHE_TYPE,
    PROJECT_USER_ROLES_CACHE_TYPE,
} from "store/services/services.tags";
import { ApiRequestBody, ApiResponse } from "types/api.types";

const BASE_PATH = "users";

type GetUserProjectRoleParams = { userId: string; projectId: number; role: PermissionRoleType };

const api = baseApi
    .enhanceEndpoints({ addTagTypes: [USER_CACHE_TYPE, USER_PROJECT_ROLE_CACHE_TYPE, PROJECT_USER_ROLES_CACHE_TYPE] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getUser: builder.query<ApiResponse<UserDto>, string>({
                query: (id) => ({
                    url: `${BASE_PATH}/${id}`,
                }),
                providesTags: (result) => (result ? [{ type: USER_CACHE_TYPE, id: result.id }] : []),
            }),
            upsertUser: builder.mutation<ApiResponse<UserDto>, ApiRequestBody<UserCreateDto>>({
                query: (body) => ({ url: `${BASE_PATH}`, method: "POST", body }),
                invalidatesTags: (result) => (result ? [{ type: USER_CACHE_TYPE, id: result.id }] : []),
            }),
            getUserProjectRole: builder.query<ApiResponse<UserProjectRoleDto>, GetUserProjectRoleParams>({
                query: ({ userId, projectId, role }) => ({
                    url: `${BASE_PATH}/${userId}/project/${projectId}/role/${role}`,
                }),
                providesTags: (_result, _error, args) => [
                    { type: USER_PROJECT_ROLE_CACHE_TYPE, id: JSON.stringify(args) },
                    { type: PROJECT_USER_ROLES_CACHE_TYPE, id: args.projectId },
                ],
            }),
        }),
    });

export const { useGetUserQuery, useUpsertUserMutation, useGetUserProjectRoleQuery } = api;
