import { HTMLAttributes } from "react";
import styled from "styled-components";

type HTMLElement = "p" | "div" | "span";
type Color = "regular" | "dark";
type Size = "sm" | "md" | "lg";
type Weight = "regular" | "medium";

interface Props extends Pick<HTMLAttributes<any>, "className" | "title" | "children"> {
    as?: HTMLElement;
    color?: Color;
    size?: Size;
    weight?: Weight;
}

const ParagraphStyled = styled.p<Required<Pick<Props, "color" | "size" | "weight">>>`
    color: ${({ theme, color }) => theme.color.text[color === "regular" ? "main" : "dark"]};

    font-size: ${({ theme, size }) => theme.font.size.body[size]};
    font-weight: ${({ weight }) => (weight === "regular" ? 400 : 500)};
    line-height: ${({ theme, size }) => theme.font.lineHeight.body[size]};

    overflow-wrap: break-word;
    white-space: pre-line;
`;

const Paragraph = ({ color = "regular", size = "md", weight = "regular", ...props }: Props) => {
    return (
        <ParagraphStyled as={props.as} color={color} size={size} weight={weight} className={props.className}>
            {props.children}
        </ParagraphStyled>
    );
};

export { Paragraph };
export type { Props as ParagraphProps, Size as ParagraphSize, Weight as ParagraphWeight };
