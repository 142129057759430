export const colors = {
    grey: {
        50: "hsl(210, 40%, 98%)",
        100: "hsl(210, 40%, 96%)",
        200: "hsl(214, 32%, 91%)",
        300: "hsl(213, 27%, 84%)",
        400: "hsl(215, 20%, 65%)",
        500: "hsl(215, 16%, 47%)",
        600: "hsl(215, 19%, 35%)",
        700: "hsl(215, 25%, 27%)",
        800: "hsl(217, 33%, 17%)",
        900: "hsl(222, 47%, 11%)",
    },
    primary: {
        50: "hsl(214, 100%, 97%)",
        100: "hsl(215, 89%, 93%)",
        200: "hsl(212, 94%, 87%)",
        300: "hsl(211, 93%, 78%)",
        400: "hsl(212, 90%, 68%)",
        500: "hsl(217, 87%, 60%)",
        600: "hsl(220, 80%, 53%)",
        700: "hsl(224, 73%, 48%)",
        800: "hsl(225, 68%, 40%)",
        900: "hsl(224, 62%, 35%)",
    },
    danger: {
        100: "hsla(2, 94%, 94%, 1)",
        500: "hsla(0, 84%, 60%, 1)",
        900: "hsla(359, 64%, 30%, 1)",
    },
    warning: {
        100: "hsla(48, 96%, 89%, 1)",
        500: "hsla(38, 92%, 50%, 1)",
        900: "hsla(22, 78%, 26%, 1)",
    },
    success: {
        100: "hsla(141, 84%, 93%, 1)",
        500: "hsla(142, 71%, 45%, 1)",
        900: "hsla(144, 61%, 20%, 1)",
    },
    info: {
        100: "hsla(204, 94%, 94%, 1)",
        500: "hsla(199, 88%, 49%, 1)",
        900: "hsla(202, 80%, 24%, 1)",
    },
};
