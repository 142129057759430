import { HTMLAttributes } from "react";
import styled from "styled-components";

type Props = Pick<HTMLAttributes<any>, "children">;

const DialogContentBodyStyled = styled.div`
    padding: 1.5rem 2.5rem 0 2.5rem;

    &:first-child {
        padding-top: 2.5rem;
    }

    &:last-child {
        padding-bottom: 2.5rem;
    }
`;

const DialogContentBody = (props: Props) => {
    return <DialogContentBodyStyled>{props.children}</DialogContentBodyStyled>;
};

export { DialogContentBody };
export type { Props as DialogContentBodyProps };
