import { Breadcrumbs as _Breadcrumbs } from "components/common/ui/Breadcrumbs/Breadcrumbs";
import type { BreadcrumbsProps } from "components/common/ui/Breadcrumbs/Breadcrumbs";
import { BreadcrumbsItem } from "components/common/ui/Breadcrumbs/BreadcrumbsItem";

const Breadcrumbs = _Breadcrumbs as typeof _Breadcrumbs & {
    Item: typeof BreadcrumbsItem;
};

Breadcrumbs.Item = BreadcrumbsItem;

export { Breadcrumbs };
export type { BreadcrumbsProps };
