import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type Entity = "project" | "product" | "ingredient";
type Item = { entity: Entity; name: string; link: string };

interface State {
    items: Item[];
}

const MAX_ITEMS = 5;

const slice = createSlice({
    name: "recent-entity",
    initialState: { items: [] as Item[] } satisfies State,
    reducers: {
        addItem: (state, action: PayloadAction<Item>) => {
            const newItem = action.payload;

            // Remove item from list if already present
            state.items = state.items.filter((item) => item.link !== newItem.link);

            // Add item to the start of the list, limit list length to 'MAX_ITEMS'
            state.items = [newItem, ...state.items.slice(0, MAX_ITEMS - 1)];
        },
    },
});

export const { addItem } = slice.actions;
export const recentEntityReducer = slice.reducer;

export type { Entity as RecentEntity };
