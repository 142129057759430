import { css } from "styled-components";

export const sidebarItemCSS = css`
    display: flex;
    align-items: center;
    width: 100%;

    padding: 0.75rem 1rem;
    border-radius: ${({ theme }) => theme.borderRadius.md};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.sm};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.sm};

    color: ${({ theme }) => theme.color.text.light};
    font-size: 0.875rem;
    line-height: 1.3rem;
    letter-spacing: 0.025rem;
    text-decoration: none;
    font-weight: 500;

    cursor: pointer;

    svg {
        color: ${({ theme }) => theme.color.icon.light};
    }

    &.active {
        position: relative;

        background-color: ${({ theme }) => theme.color.background.white};
        color: ${({ theme }) => theme.color.primary.text};

        svg {
            color: ${({ theme }) => theme.color.primary.icon.main};
        }

        &::before {
            position: absolute;
            right: 0;
            top: 0;

            height: 100%;
            width: 0.25rem;

            border-top-right-radius: ${({ theme }) => theme.borderRadius.sm};
            border-bottom-right-radius: ${({ theme }) => theme.borderRadius.sm};

            content: "";

            background-color: ${({ theme }) => theme.color.primary.background.main};
        }
    }

    &:hover {
        color: ${({ theme }) => theme.color.text.dark};

        svg {
            color: ${({ theme }) => theme.color.icon.main};
        }
    }
`;
