import { useLocation } from "react-router-dom";

import { Breadcrumbs } from "components/common/ui/Breadcrumbs";
import { PathBreadcrumbsEntityItem } from "components/layout/PathBreadcrumbs/PathBreadcrumbsEntityItem";
import { PathBreadcrumbsTextItem } from "components/layout/PathBreadcrumbs/PathBreadcrumbsTextItem";
import { getItems } from "components/layout/PathBreadcrumbs/path-breadcrumbs.utils";

const PathBreadcrumbs = () => {
    const location = useLocation();
    const pathSegments = location.pathname.split("/").slice(1);
    const items = getItems(pathSegments);

    return (
        <Breadcrumbs>
            {items.map((item, index) =>
                item.relatedEntity && item.params ? (
                    <PathBreadcrumbsEntityItem
                        key={`${index}-${item.path}`}
                        {...item}
                        relatedEntity={item.relatedEntity}
                        params={item.params}
                    />
                ) : (
                    <PathBreadcrumbsTextItem key={`${index}-${item.path}`} {...item} />
                )
            )}
        </Breadcrumbs>
    );
};

export { PathBreadcrumbs };
