import { Caption } from "components/common/typography/Caption";
import { Headline } from "components/common/typography/Headline";
import { Paragraph } from "components/common/typography/Paragraph";

const Typography = {} as {
    Caption: typeof Caption;
    Headline: typeof Headline;
    Paragraph: typeof Paragraph;
};

Typography.Caption = Caption;
Typography.Headline = Headline;
Typography.Paragraph = Paragraph;

export { Typography };
