import { HTMLAttributes } from "react";
import styled from "styled-components";

type HTMLElement = "p" | "div" | "span";
type Size = "sm" | "md";

interface Props extends Pick<HTMLAttributes<any>, "className" | "title" | "children"> {
    as?: HTMLElement;
    size?: Size;
}

const CaptionStyled = styled.p<Required<Pick<Props, "size">>>`
    color: ${({ theme }) => theme.color.text.light};

    font-size: ${({ theme, size }) => theme.font.size.caption[size]};
    line-height: ${({ theme, size }) => theme.font.lineHeight.caption[size]};

    overflow-wrap: break-word;
    white-space: pre-line;
`;

const Caption = ({ size = "md", ...props }: Props) => {
    return (
        <CaptionStyled as={props.as} size={size} className={props.className}>
            {props.children}
        </CaptionStyled>
    );
};

export { Caption };
export type { Props as CaptionProps, Size as CaptionSize };
