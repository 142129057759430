import { EntityBreadcrumbs, Params } from "components/layout/PathBreadcrumbs/path-breadcrumbs.types";
import { useGetIngredientQuery } from "store/services/ingredient.service";
import { useGetProductQuery } from "store/services/product.service";
import { useGetProjectQuery } from "store/services/project.service";

type GetEntityFn = (arg: any) => { data?: any };
const entityGetterHookMap: Record<EntityBreadcrumbs, GetEntityFn> = {
    projects: useGetProjectQuery,
    products: useGetProductQuery,
    ingredients: useGetIngredientQuery,
};

const entityGetterHookArgMap: Record<EntityBreadcrumbs, (params: Params) => string> = {
    ingredients: (params) => params.ingredientId,
    products: (params) => params.productId,
    projects: (params) => params.projectId,
};

const entityTitleMap: Record<EntityBreadcrumbs, (value: any) => string> = {
    ingredients: (data) => data.name,
    products: (data) => data.name,
    projects: (data) => data.name,
};

export const useEntity = (entity: EntityBreadcrumbs, params: Params) => {
    const useGetEntity = entityGetterHookMap[entity];
    const getEntityArg = entityGetterHookArgMap[entity];

    const entityArg = getEntityArg(params);
    const { data } = useGetEntity(entityArg);

    const title = data ? entityTitleMap[entity](data) : entityArg;
    return title;
};
