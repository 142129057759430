import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import "modern-normalize";

import { App } from "components/App";
import { config } from "config";

// eslint-disable-next-line no-console
console.log(`Integrated Formulation System v${APP_VERSION} on "${config.MODE}" environment`);

if (config.SENTRY_DSN) {
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.MODE,
        integrations: [
            new BrowserTracing(),
            new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: false,
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1,
        replaysOnErrorSampleRate: 1.0,
        release: `ifs-frontend-${APP_VERSION}`,
    });
}

if (config.LOGROCKET_TOKEN) {
    LogRocket.init(config.LOGROCKET_TOKEN);
    setupLogRocketReact(LogRocket);
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
