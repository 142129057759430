import { ProductCreateDto } from "@api/product/dto/product-create.dto";
import { ProductDetailDto } from "@api/product/dto/product-detail.dto";
import { ProductDictionariesDto } from "@api/product/dto/product-dictionaries.dto";
import { ProductUpdateDto } from "@api/product/dto/product-update.dto";
import { ProductDto } from "@api/product/dto/product.dto";
import { ProductVersionInfoDto } from "@api/product-version/dto/product-version-info.dto";
import { ProductionStepDto } from "@api/production-step/dto/production-step.dto";

import { api as baseApi } from "store/api";
import {
    PRODUCT_CACHE_TYPE,
    PRODUCT_CACHE_LIST_ID,
    INGREDIENT_CACHE_TYPE,
    INGREDIENT_CACHE_LIST_ID,
    PROJECT_CACHE_TYPE,
} from "store/services/services.tags";
import { tagsForOptionalIds } from "store/services/services.utils";
import { ApiRequestBody, ApiResponse } from "types/api.types";

const BASE_PATH = "products";

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [PRODUCT_CACHE_TYPE, INGREDIENT_CACHE_TYPE, PROJECT_CACHE_TYPE],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getProducts: builder.query<ApiResponse<ProductDto>[], void>({
                query: () => BASE_PATH,
                providesTags: (result) =>
                    result
                        ? [
                              ...result.map(({ id }) => ({ type: PRODUCT_CACHE_TYPE, id } as const)),
                              { type: PRODUCT_CACHE_TYPE, id: PRODUCT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            getProduct: builder.query<ApiResponse<ProductDetailDto>, number>({
                query: (id) => `${BASE_PATH}/${id}`,
                providesTags: (result) =>
                    result
                        ? [
                              { type: PRODUCT_CACHE_TYPE, id: result.id },
                              ...tagsForOptionalIds(PROJECT_CACHE_TYPE, [result.projectId]),
                          ]
                        : [],
            }),
            getProductDictionaries: builder.query<ApiResponse<ProductDictionariesDto>, number>({
                query: (projectId) => `${BASE_PATH}/dictionaries/${projectId}`,
                providesTags: (result) =>
                    result
                        ? [
                              ...Object.keys(result.formulationIngredientId).map(
                                  (id) => ({ type: INGREDIENT_CACHE_TYPE, id } as const)
                              ),
                              { type: INGREDIENT_CACHE_TYPE, id: INGREDIENT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            getProductProductionSteps: builder.query<ApiResponse<ProductionStepDto>[], number>({
                query: (id) => `${BASE_PATH}/${id}/productionSteps`,
                providesTags: (result, error, productId) =>
                    result
                        ? [
                              { type: PRODUCT_CACHE_TYPE, id: productId },
                              ...tagsForOptionalIds(
                                  INGREDIENT_CACHE_TYPE,
                                  result.map(({ formulationIngredientId }) => formulationIngredientId)
                              ),
                          ]
                        : [],
            }),
            getProductVersions: builder.query<ApiResponse<ProductVersionInfoDto>[], number>({
                query: (id) => `${BASE_PATH}/${id}/versions`,
                providesTags: (result, error, productId) =>
                    result ? [{ type: PRODUCT_CACHE_TYPE, id: productId }] : [],
            }),
            createProduct: builder.mutation<ApiResponse<ProductDto>, ApiRequestBody<ProductCreateDto>>({
                query: (body) => ({
                    url: `${BASE_PATH}`,
                    method: "POST",
                    body,
                }),
                invalidatesTags: (result) =>
                    result
                        ? [
                              { type: PRODUCT_CACHE_TYPE, id: result.id },
                              { type: PRODUCT_CACHE_TYPE, id: PRODUCT_CACHE_LIST_ID },
                          ]
                        : [],
            }),
            updateProduct: builder.mutation<
                ApiResponse<ProductDto>,
                ApiRequestBody<{ id: number; body: ProductUpdateDto }>
            >({
                query: (args) => ({
                    url: `${BASE_PATH}/${args.id}`,
                    method: "PUT",
                    body: args.body,
                }),
                invalidatesTags: (result) => (result ? [{ type: PRODUCT_CACHE_TYPE, id: result.id }] : []),
            }),
        }),
    });

export const {
    useGetProductsQuery,
    useGetProductQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useGetProductDictionariesQuery,
    useGetProductProductionStepsQuery,
    useGetProductVersionsQuery,
} = api;
