import * as RadixDialog from "@radix-ui/react-dialog";
import { HTMLAttributes } from "react";

type Props = Pick<HTMLAttributes<any>, "children">;

const DialogTrigger = (props: Props) => {
    return <RadixDialog.Trigger asChild={true}>{props.children}</RadixDialog.Trigger>;
};

export { DialogTrigger };
export type { Props as DialogTriggerProps };
