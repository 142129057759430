import { css } from "styled-components";

export const datepickerCSS = css`
    body {
        width: 100%;

        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                height: 100%;
            }
        }

        .react-datepicker {
            border: 0;
            border-radius: ${({ theme }) => theme.borderRadius.sm};

            font-size: ${({ theme }) => theme.font.size.body.md};
            line-height: 1;
        }

        .react-datepicker__navigation {
            height: 2.25rem;
        }

        .react-datepicker__navigation:hover::before {
            border-color: ${({ theme }) => theme.color.background.main};
        }

        .react-datepicker__navigation--previous {
            top: 0;
            left: 0;
        }

        .react-datepicker__navigation--next {
            top: 0;
            right: 0;
        }

        .react-datepicker__navigation-icon {
            position: relative;
            top: 0;
            width: 1.25rem;
            height: 1.25rem;
            font-size: 1.25rem;
        }

        .react-datepicker__month-container {
            width: 26rem;

            border: 1px solid ${({ theme }) => theme.color.border};
            border-radius: ${({ theme }) => theme.borderRadius.sm};
            box-shadow: ${({ theme }) => theme.boxShadow.overlay};
        }

        .react-datepicker__header {
            padding-top: 0.5rem;
            background-color: ${({ theme }) => theme.color.background.main};

            border: 0;
        }

        .react-datepicker__current-month {
            margin-bottom: 0.5rem;

            font-weight: 500;
        }

        .react-datepicker__day-name {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.25rem;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            font-weight: 500;
            line-height: 2.5rem;
        }

        .react-datepicker__day-name:nth-child(6),
        .react-datepicker__day-name:nth-child(7) {
            color: ${({ theme }) => theme.color.text.main};
        }

        .react-datepicker__day-name:not(:last-child) {
            border: 0;
        }

        .react-datepicker__month {
            margin: 0;
            padding: 0 0.5rem;

            background-color: ${({ theme }) => theme.color.background.white};
            border-radius: ${({ theme }) => theme.borderRadius.sm};
        }

        .react-datepicker__day {
            width: 2.5rem;
            height: 2.5rem;
            margin: 0.25rem;

            display: inline-flex;
            align-items: center;
            justify-content: center;

            border-radius: ${({ theme }) => theme.borderRadius.sm};
            line-height: 2.5rem;

            &:hover {
                border-radius: ${({ theme }) => theme.borderRadius.sm};

                background-color: ${({ theme }) => theme.color.background};
            }
        }

        .react-datepicker__day--today {
            background-color: ${({ theme }) => theme.color.background.main};
        }

        .react-datepicker__day--selected {
            background-color: ${({ theme }) => theme.color.primary.background.main};

            color: ${({ theme }) => theme.color.background.white} !important;

            outline: none;

            &:hover {
                background-color: ${({ theme }) => theme.color.primary.background.light};
            }
        }

        .react-datepicker__day--keyboard-selected {
            background-color: ${({ theme }) => theme.color.background.main};
            outline: none;
            color: unset;

            &:hover {
                background-color: ${({ theme }) => theme.color.background.main};
            }
        }

        .react-datepicker__day--weekend {
            color: ${({ theme }) => theme.color.text.light};
        }

        .react-datepicker__day--outside-month {
            color: ${({ theme }) => theme.color.text.disabled};
        }
    }
`;
