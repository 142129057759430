import { createGlobalStyle } from "styled-components";

import { datepickerCSS } from "components/common/form/Input/Date/styles.datepicker";

import "react-datepicker/dist/react-datepicker.css";

export const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
    }
  
    html,
    body,
    #root {
        min-height: 100%;
    }
    
    body {
        font-family: "Inter", system-ui, "Segoe UI", Roboto, Helvetica, Arial,
            sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        font-size: ${({ theme }) => theme.font.size.body.md};
        line-height: 1;
        letter-spacing: 0;
        color: ${({ theme }) => theme.color.text.main};
    }
    
    @supports (font-variation-settings: normal) {
        body {
            font-family: "Inter var", system-ui, "Segoe UI", Roboto, Helvetica, Arial,
                sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
        }
    }
    
    ${datepickerCSS};
`;
