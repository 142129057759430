import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { AuthProvider } from "components/auth/AuthProvider";
import { LoginProvider } from "components/auth/LoginProvider";
import { AppRoutes } from "components/routes/AppRoutes";
import { store } from "store/store";
import { GlobalStyles } from "styles/global.style";
import { theme } from "styles/theme";

const App = () => {
    return (
        <BrowserRouter>
            <ReduxProvider store={store}>
                <AuthProvider>
                    <LoginProvider>
                        <ThemeProvider theme={theme}>
                            <GlobalStyles />
                            <AppRoutes />
                        </ThemeProvider>
                    </LoginProvider>
                </AuthProvider>
            </ReduxProvider>
        </BrowserRouter>
    );
};

export { App };
